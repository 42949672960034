import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import VueRouter from "vue-router";
import Notifications from "vue-notification";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.css";
import "./assets/css/style.css";
import util from "@/util/util.js";
import moment from "moment";
import VModal from "vue-js-modal";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import * as VueGoogleMaps from "vue2-google-maps";

import Paginate from "vuejs-paginate";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("en", en);

// localize("en", en);

Vue.prototype.$http = util.http;
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(VModal);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("paginate", Paginate);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDCUjF3p0Km3gQ7sB3BNnknT_eMdL65QFY",
    libraries: "places",
  },
});

Vue.mixin({
  methods: {
    can(permission) {
      return this.$store.state.permissions.indexOf(permission) != -1;
    },

    isLoggedIn() {
      let token = sessionStorage.getItem("token");
      if (token) {
        return true;
      }
      return false;
    },

    fetchPermissions() {
      util
        .http({
          url: "role/permissions/user-permissions",
        })
        .then((resp) => {
          if (resp.data.status == 1) {
            this.$store.commit("setPermissions", resp.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProfile() {
      util
        .http({
          url: "profile",
        })
        .then((resp) => {
          this.$store.commit("setProfile", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCompany() {
      util
        .http({
          url: `company`,
          method: "get",
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            this.$store.commit("setCompany", res.data.data);
          }
        })
        .catch();
    },
    toDMY(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    statusHtml(val) {
      if (val == 1) {
        return '<span class="badge badge-success">Active</span>';
      }
      return '<span class="badge badge-danger">Inactive</span>';
    },
    successHtml(val) {
      if (val == "Success") {
        return '<span class="badge badge-success">' + val + "</span>";
      }
      return '<span class="badge badge-danger">' + val + "</span>";
    },
    requriedHtml() {
      return '<span class="text-danger">*</span>';
    },
  },
});
Vue.component("notify", require("@/components/shared/notify.vue").default);
let app = new Vue({
  router,
  store,

  // mounted() {
  //   // this.routeWatcher = this.$watch(
  //   //   function () {
  //   //     return this.$route;
  //   //   },

  //   //   function (route) {
  //   //     this.fetchCompany();
  //   //     if (
  //   //       route.name != "Login" &&
  //   //       route.name != "forgot-password" &&
  //   //       route.name != "ResetPassword"
  //   //     ) {
  //   //       this.fetchPermissions();
  //   //       this.getProfile();
  //   //     }
  //   //   }
  //   // );
  // },
  render: (h) => h(App),
}).$mount("#app");
if (app.isLoggedIn()) {
  app.fetchCompany();
  app.fetchPermissions();
  app.getProfile();
}
export default app;
